import {
    CreditCardUpdateFailed,
    CreditCardUpdated,
    PaymentInfoFetched,
    PlanCancelFailed,
    PlanCanceled,
    PlanChangeFailed,
    PlanChangeStarted,
    PlanChangeSucceeded,
    PlanReactivated,
    PlanReactivationFailed,
    PurchaseFailed,
    PurchaseStarted,
    PurchaseSucceeded,
    StorageProductsFetched,
    FetchStripeSubscriptionInfoFailed,
    FetchStripeSubscriptionInfoSucceeded,
    UserStoragePlanFetched,
} from '~/state/storagePlan/actions'
import type { Dispatch } from '~/state/common/actions'
import { stripeMode } from '~/config/constants'
import type { StripeSubscriptionResponse } from '~/@types/backend-types'
import { validateSCA } from './3rdParty/stripe'
import { getServiceProvider } from './HostProvider'
import { getStripeService } from './services/StripeService'
import { ResponseNotOKError } from './toolbox'
import { fetchAccountInfo } from './currentUser'

export const fetchCreditCardInfo = async (dispatch: Dispatch) => {
    try {
        const paymentInfoResp =
            await getStripeService().getStripePaymentMethodInfo()
        dispatch(PaymentInfoFetched(paymentInfoResp))
    } catch (error) {
        if (error instanceof ResponseNotOKError) {
            // We get 404 Not Found when a user does not have any credit card
            if (error.response.status === 404) {
                dispatch(PaymentInfoFetched(null))
            }
        }
    }
}
export const updateCreditCardInfo = async (
    dispatch: Dispatch,
    paymentTokenID: string,
    cardID: string,
) => {
    try {
        await getStripeService().postStripePaymentMethod(paymentTokenID, cardID)
        dispatch(CreditCardUpdated())
    } catch (error) {
        dispatch(CreditCardUpdateFailed())
    }
}

/**
 * Saga for interacting with Stripe and Backend for first-time purchase of storage-plan.
 * SCA complicates flow and introduces tight coupling between flow and backend return type (handling partial payments)
 * TODO?: Replace consumers with StripeForms to obtain access to `stripe`-object in Component for SCA etc there
 */
export const startPlanSubscription = async (
    dispatch: Dispatch,
    planID: string,
    paymentTokenID: string,
    cardID: string,
) => {
    try {
        dispatch(PurchaseStarted())
        const purchaseResponse = await getStripeService().stripePurchase({
            plan: planID,
            card: cardID,
            token: paymentTokenID,
        })

        if (purchaseResponse.status === 'incomplete') {
            const sca = await validateSCA(
                purchaseResponse.client_secret,
                cardID,
            )
            const validateResponseCode =
                await getStripeService().validateStripePurchase(
                    purchaseResponse.subscription_id,
                    sca.id,
                )
            if (validateResponseCode.status !== 200) {
                throw new Error('Validate stripe purchase failed', {
                    cause: {
                        name: validateResponseCode.status.toString(),
                        message: validateResponseCode.statusText,
                    },
                })
            }
        }
        dispatch(PurchaseSucceeded())
        refreshUserSubscriptionInfo(dispatch)
    } catch (error) {
        console.error(error)
        dispatch(PurchaseFailed())
    }
}

const refreshUserSubscriptionInfo = (dispatch: Dispatch) => {
    return Promise.all([
        fetchAccountInfo(dispatch),
        getUserStoragePlan(dispatch),
        getUserStripeSubscriptions(dispatch),
    ])
}

const getStorageProducts = async (dispatch: Dispatch) => {
    try {
        const resp = await getStripeService().getStripeProducts(stripeMode)
        dispatch(StorageProductsFetched(resp))
    } catch (e) {
        /* No harm done */
    }
}

export const getUserStoragePlan = async (dispatch: Dispatch) => {
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        const grants = await service.getUserGrants()
        dispatch(UserStoragePlanFetched(grants))
    } catch (e) {
        /* No harm done */
    }
}

export const fetchStoragePlanInfo = (dispatch: Dispatch) => {
    return Promise.all([
        getStorageProducts(dispatch),
        getUserStoragePlan(dispatch),
        getUserStripeSubscriptions(dispatch),
    ])
}

export const cancelStoragePlan = async (dispatch: Dispatch, url: string) => {
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.executeGrantLink(url)
        dispatch(PlanCanceled())

        refreshUserSubscriptionInfo(dispatch)
    } catch (e) {
        dispatch(PlanCancelFailed())
    }
}

export const reactivateStoragePlan = async (
    dispatch: Dispatch,
    url: string,
) => {
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.executeGrantLink(url)
        dispatch(PlanReactivated())

        refreshUserSubscriptionInfo(dispatch)
    } catch (e) {
        dispatch(PlanReactivationFailed())
    }
}

export const subscribeToStoragePlan = async (
    dispatch: Dispatch,
    plan: string,
) => {
    try {
        dispatch(PurchaseStarted())
        await getStripeService().purchaseStripePlan(plan)
        dispatch(PurchaseSucceeded())

        refreshUserSubscriptionInfo(dispatch)
    } catch (e) {
        dispatch(PurchaseFailed())
    }
}

export const getUserStripeSubscriptions = async (dispatch: Dispatch) => {
    try {
        const { data } = await getStripeService().getAllSubscriptions()
        dispatch(
            FetchStripeSubscriptionInfoSucceeded(
                data as StripeSubscriptionResponse,
            ),
        )
    } catch (e) {
        dispatch(FetchStripeSubscriptionInfoFailed())
    }
}

export const changeStripePlan = async (dispatch: Dispatch, plan: string) => {
    try {
        dispatch(PlanChangeStarted())
        await getStripeService().updateStripePlan(plan)
        dispatch(PlanChangeSucceeded({ newPlanID: plan }))

        refreshUserSubscriptionInfo(dispatch)
    } catch (e) {
        dispatch(PlanChangeFailed())
    }
}

/**
 * @deprecated This function is deprecated and will be removed after stripe setup intent migration.
 */
export const changeCurrentPlan = async (
    dispatch: Dispatch,
    targetPlanID: string,
) => {
    try {
        dispatch(PlanChangeStarted())
        await getStripeService().updateCurrentPlan(targetPlanID)
        dispatch(PlanChangeSucceeded({ newPlanID: targetPlanID }))

        refreshUserSubscriptionInfo(dispatch)
    } catch (e) {
        dispatch(PlanChangeFailed())
    }
}

/**
 * @deprecated This function is deprecated and will be removed after stripe setup intent migration.
 */
export const deleteCreditCard = async (cardId: string) => {
    const response = await getStripeService().deleteUserCreditCard(cardId)
    return response
}
