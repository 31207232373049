/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKCreateCustomerPostParams,
    CAPBAKCreateSetupIntentPostParams,
    CAPBAKCustomerExistsGetParams,
    CAPBAKFinishSetupIntentPostParams,
    CAPBAKHTTPValidationError,
    CAPBAKMessageResponse,
    CAPBAKPurchaseStripeSubscriptionResponse,
    CAPBAKStripeSetupIntentCreateResponse,
    CAPBAKStripeSubscriptionActionRequiredResponse,
    CAPBAKStripeSubscriptionResponse,
    CAPBAKSubscriptionsGetParams,
    CAPBAKUpdateStripePurchaseResponse,
    CAPBAKV1PurchasePostParams,
    CAPBAKV1PurchaseUpdateParams,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class Stripe<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags stripe
     * @name V1PurchaseUpdate
     * @summary Update Stripe Purchase New
     * @request PUT:/stripe/v1/purchase
     */
    v1PurchaseUpdate = (
        query: CAPBAKV1PurchaseUpdateParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKUpdateStripePurchaseResponse,
            CAPBAKHTTPValidationError
        >({
            path: `/stripe/v1/purchase`,
            method: 'PUT',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags stripe
     * @name V1PurchasePost
     * @summary Post Stripe Purchase New
     * @request POST:/stripe/v1/purchase
     */
    v1PurchasePost = (
        query: CAPBAKV1PurchasePostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            | CAPBAKPurchaseStripeSubscriptionResponse
            | CAPBAKStripeSubscriptionActionRequiredResponse,
            CAPBAKHTTPValidationError
        >({
            path: `/stripe/v1/purchase`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags stripe
     * @name CreateSetupIntentPost
     * @summary Post Create Setup Intent
     * @request POST:/stripe/create_setup_intent
     */
    createSetupIntentPost = (
        query: CAPBAKCreateSetupIntentPostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKStripeSetupIntentCreateResponse,
            CAPBAKHTTPValidationError
        >({
            path: `/stripe/create_setup_intent`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags stripe
     * @name FinishSetupIntentPost
     * @summary Post Finish Setup Intent
     * @request POST:/stripe/finish_setup_intent
     */
    finishSetupIntentPost = (
        query: CAPBAKFinishSetupIntentPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/stripe/finish_setup_intent`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags stripe
     * @name CustomerExistsGet
     * @summary Get Customer Exists
     * @request GET:/stripe/customer_exists
     */
    customerExistsGet = (
        query: CAPBAKCustomerExistsGetParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/stripe/customer_exists`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags stripe
     * @name SubscriptionsGet
     * @summary Get Stripe Subscriptions
     * @request GET:/stripe/subscriptions
     */
    subscriptionsGet = (
        query: CAPBAKSubscriptionsGetParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKStripeSubscriptionResponse,
            CAPBAKHTTPValidationError
        >({
            path: `/stripe/subscriptions`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * No description
     *
     * @tags stripe
     * @name CreateCustomerPost
     * @summary Post Stripe Create Customer
     * @request POST:/stripe/create_customer
     */
    createCustomerPost = (
        query: CAPBAKCreateCustomerPostParams,
        params: RequestParams = {},
    ) =>
        this.request<CAPBAKMessageResponse, CAPBAKHTTPValidationError>({
            path: `/stripe/create_customer`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
