/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKCancelPostParams,
    CAPBAKHTTPValidationError,
    CAPBAKPurchaseStripeSubscriptionResponse,
    CAPBAKReactivatePostParams,
    CAPBAKStripePurchasePostParams,
    CAPBAKStripeSubscriptionActionRequiredResponse,
    CAPBAKValidatePurchaseDetailParams,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class StripePurchase<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description Validate if subscription is paid successfully
     *
     * @tags stripe
     * @name ValidatePurchaseDetail
     * @summary Validate Purchase Status
     * @request GET:/stripe_purchase/{subscription_id}/validate_purchase
     */
    validatePurchaseDetail = (
        { subscriptionId, ...query }: CAPBAKValidatePurchaseDetailParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/stripe_purchase/${subscriptionId}/validate_purchase`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Reactive a cancelled subscription while it is still active
     *
     * @tags stripe
     * @name CancelPost
     * @summary Cancel Stripe Purchase
     * @request POST:/stripe_purchase/{subscription_id}/cancel
     */
    cancelPost = (
        { subscriptionId, ...query }: CAPBAKCancelPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/stripe_purchase/${subscriptionId}/cancel`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Reactive a cancelled subscription while it is still active
     *
     * @tags stripe
     * @name ReactivatePost
     * @summary Reactivate Stripe Purchase
     * @request POST:/stripe_purchase/{subscription_id}/reactivate
     */
    reactivatePost = (
        { subscriptionId, ...query }: CAPBAKReactivatePostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/stripe_purchase/${subscriptionId}/reactivate`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Make a web store/stripe purchase
     *
     * @tags stripe
     * @name StripePurchasePost
     * @summary Post Stripe Purchase
     * @request POST:/stripe_purchase
     */
    stripePurchasePost = (
        query: CAPBAKStripePurchasePostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            | CAPBAKPurchaseStripeSubscriptionResponse
            | CAPBAKStripeSubscriptionActionRequiredResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/stripe_purchase`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
