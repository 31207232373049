import type { NavigateFunction } from 'react-router-dom'
import { LOGGED_IN_SITE_ADDRESS } from '~/config/constants'
import type { Dispatch } from '~/state/common/actions'
import {
    TOSTermsVerified,
    UnverifiedTermsDetected,
    UserLoggedOut,
} from '~/state/currentUser/actions'
import { goToOpenWeb } from '~/utilities/appLink'
import type { PageDescription } from '~/utilities/navigation'
import { getAbsoluteURLOfPage } from '~/utilities/urlParsing'
import { localStorageSet, sessionStorageSet } from '~/utilities/webStorage'
import { fetchAccountAttributes, fetchAccountInfo } from './currentUser'
import { clearLoginInfo, setInitialAuthValues } from './externals'
import { getServiceProvider } from './HostProvider'
import { fetchDefaultJobID } from './job'
import { getLoginServiceForApp } from './services'

export async function fetchAccountInfoNicely(
    dispatch: Dispatch,
): Promise<void> {
    return fetchAccountInfo(dispatch)
        .then((accountInfo) => {
            if (accountInfo === undefined) {
                return
            }

            if (accountInfo.tos_to_approve) {
                dispatch(UnverifiedTermsDetected(accountInfo.tos_to_approve))
            }
            fetchDefaultJobID(dispatch)
        })
        .then(() => {
            fetchAccountAttributes(dispatch)
        })
}

export const acceptTOSVersion = async (dispatch: Dispatch, version: string) => {
    const service = getServiceProvider().getAppServiceForLoggedInUserDefaults()
    await service.approveTOS(version)
    dispatch(TOSTermsVerified())
}

export const requireAccountInfo = async (
    dispatch: Dispatch,
    gotoIfLoginFail?: string,
    utmOverride?: string,
): Promise<void> => {
    try {
        await fetchAccountInfoNicely(dispatch)
    } catch {
        loginAndGoToUrl(window.location.href, gotoIfLoginFail, utmOverride)
    }
}
export const loadAccountInfoIfAvailable = async (
    dispatch: Dispatch,
): Promise<void> => {
    try {
        await fetchAccountInfoNicely(dispatch)
    } catch {
        /* We didn't get account info - that is okay */
    }
}

const _logout = async () => {
    try {
        const service =
            getServiceProvider().getAppServiceForLoggedInUserDefaults()
        await service.logout()
    } catch (_e) {
        // we do not handle errors here
    } finally {
        clearLoginInfo()
    }
}
export const logout = async (
    dispatch: Dispatch,
    navigate: NavigateFunction,
) => {
    // TODO: TESTS etc
    try {
        await _logout()
        dispatch(UserLoggedOut())
        navigate('/')
    } catch (e) {
        /* What if logout fails */
    }
}

// Do not dispatch UserLoggedOut action, to avoid transit to LoginRequiredPage before redirect
export const logoutAndRedirectToOpenWeb = async (dispatch: Dispatch) => {
    try {
        await _logout()
        dispatch(UserLoggedOut())
    } catch (_e) {
        /* What if logout fails */
    } finally {
        goToOpenWeb()
    }
}

export const doLogin = (utmOverride?: string) =>
    loginAndGoToUrl(location.href, undefined, utmOverride)
export const loginAndGoToUrl = (
    targetPath: URLstring,
    ifFail?: URLstring,
    utmOverride?: string,
) => {
    sessionStorageSet('historyLengthBeforeLogin', history.length.toString())
    localStorageSet('locationBeforeLogin', targetPath)

    if (ifFail) {
        localStorageSet('locationIfLoginFails', ifFail)
    }
    // Start the login flow using the method from the LoginService
    getLoginServiceForApp().login(utmOverride)

    // Return a never-resolving Promise as we will be redirecting insteadof returning any value.
    return new Promise(() => {})
}

export const loginToLoggedInWebPage = (
    targetPage: PageDescription,
    ifFail?: URLstring,
) => {
    loginAndGoToUrl(
        LOGGED_IN_SITE_ADDRESS + targetPage.url,
        ifFail || window.location.href,
    ).catch()
}

export const loginAndGoToPage = (
    page: PageDescription,
    ifFail?: URLstring,
    utmOverride?: string,
) => {
    loginAndGoToUrl(getAbsoluteURLOfPage(page), ifFail, utmOverride).catch()
}

export const overrideAccountFromSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const authToken = searchParams.get('authToken')
    const serviceDict = searchParams.get('serviceDict')

    if (authToken && serviceDict) {
        setInitialAuthValues(authToken, serviceDict)
    }
}
