/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKStripeProductListResponse,
    CAPBAKStripeProductsGetParams,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class StripeProducts<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description A list of available web store/stripe products
     *
     * @tags in_app_purchase
     * @name StripeProductsGet
     * @summary Get Stripe Products
     * @request GET:/stripe_products
     */
    stripeProductsGet = (
        query: CAPBAKStripeProductsGetParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKStripeProductListResponse,
            CAPBAKHTTPValidationError
        >({
            path: `/stripe_products`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
}
