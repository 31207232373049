import { CAPBAKClientGrantor } from '@capture/client-api/src/schemas/data-contracts'
import { createSelector } from 'reselect'
import type {
    StripeProduct,
    StripeUserGrant,
    UserGrant,
} from '~/@types/backend-types'
import { localizedDateStringShort } from '~/components/Common/TimeStrings'
import { cachedInArray, groupArray } from '~/utilities/arrayUtils'
import { bytesToSize } from '~/utilities/fileSizeFormatting'
import { getMaxStorage, getUsedStorage } from '../currentUser/selectors'
import type { StateWithStoragePlan } from './reducer'

const getStoragePlanState = (state: StateWithStoragePlan) => state.storagePlan

// TODO get bytes sizes from backend
export const getAvailableProducts = createSelector(
    getStoragePlanState,
    (storagePlan) =>
        storagePlan.availableProducts.map((p) => ({
            ...p,
            size: p.size * Math.pow(2, 30),
        })),
)

export const getAvailableTrialPeriod = createSelector(
    getStoragePlanState,
    (storagePlan) => storagePlan.availableTrial,
)

export const getCurrentUserGrants = createSelector(
    getStoragePlanState,
    (storagePlan) =>
        storagePlan.userGrants.map((g) => ({
            ...g,
            size: g.size * Math.pow(2, 30),
        })),
)

export const isUpdatingStoragePlan = createSelector(
    getStoragePlanState,
    (storagePlan) => storagePlan.isUpdatingStoragePlan,
)

export const getCreditCardInfo = createSelector(
    getStoragePlanState,
    (storagePlan) => storagePlan.paymentInfo,
)

export const getPriceString = (price: number, currency: string) => {
    const normalisedCurrency = (price / 100).toFixed(price % 100 === 0 ? 0 : 2)
    return `${currency.toUpperCase()} ${normalisedCurrency}`
}

export const getStripeSubscriptionInfo = createSelector(
    getStoragePlanState,
    (state) => state.stripeSubscriptionInfo,
)

export const getUpcomingStripeProduct = createSelector(
    getStripeSubscriptionInfo,
    (stripeSubscriptionInfo) => stripeSubscriptionInfo?.upcomingProduct,
)

export type StoragePlan = {
    size: number
    isCanceled: boolean
}

export type StripeStoragePlan = StripeProduct &
    StoragePlan & {
        priceString: string
        planActionLink: string
        validToDate: string
        isOverrided: boolean
    }

export const getCurrentStripeStoragePlan = createSelector(
    getAvailableProducts,
    getCurrentUserGrants,
    getUpcomingStripeProduct,
    (
        products,
        grants,
        upcomingStripeProduct,
    ): StripeStoragePlan | undefined => {
        const isAvailable = cachedInArray(products.map((p) => p.id))
        const currentGrant = grants.find(
            (g): g is StripeUserGrant =>
                g.source === CAPBAKClientGrantor.CaptureStripe &&
                (g.renews_at !== undefined || g.expires_at !== undefined) &&
                isAvailable(g.stripe_product_id),
        )
        if (currentGrant) {
            const { _links, renews_at, expires_at, stripe_product_id } =
                currentGrant
            const currentProduct = products.filter(
                (p) => p.id === stripe_product_id,
            )[0]
            const validToDate = renews_at || expires_at
            return {
                ...currentProduct,
                isCanceled: expires_at !== undefined,
                isOverrided:
                    upcomingStripeProduct !== undefined &&
                    upcomingStripeProduct.id !== stripe_product_id,
                priceString: getPriceString(
                    currentProduct.price,
                    currentProduct.currency,
                ),
                planActionLink: (_links?.cancellation || _links?.reactivation)!,
                validToDate: localizedDateStringShort(
                    new Date(validToDate! * 1000),
                ),
            }
        }
    },
)

export const getCaptureIncludedStoragePlan = createSelector(
    getCurrentUserGrants,
    (grants): StoragePlan | undefined => {
        const captureGrant = grants.find((g) => g.source === 'capture')

        if (captureGrant) {
            return {
                size: captureGrant.size,
                isCanceled: false,
            }
        }
    },
)

const unlimitedStorageThreshold = 1000 * Math.pow(2, 30) // 1000 GB
export const getAggregatedUserGrants = createSelector(
    getCurrentUserGrants,
    (grants): UserGrant[] => {
        const shouldAggregate = ({ source }: UserGrant) =>
            source === CAPBAKClientGrantor.CustomerService ||
            source === CAPBAKClientGrantor.Other ||
            source === CAPBAKClientGrantor.Capture
        const definiteGrants = grants.filter(
            (g) =>
                !(
                    (
                        shouldAggregate(g) ||
                        (g.source === 'b2b' &&
                            g.size >= unlimitedStorageThreshold)
                    ) // unlimited storage will be handled separately
                ),
        )

        const aggregateGrants = grants.filter(shouldAggregate)
        const aggregateGrantGroups = groupArray(
            aggregateGrants,
            (g) => g.source,
        )
        const aggregatedGrants = Object.keys(aggregateGrantGroups).map(
            (group) => ({
                ...aggregateGrantGroups[group][0],
                size: aggregateGrantGroups[group].reduce(
                    (s, g) => s + g.size,
                    0,
                ),
            }),
        )

        return aggregatedGrants.concat(definiteGrants)
    },
)

export type CaptureStripeSource = {
    sourceName: CAPBAKClientGrantor.CaptureStripe
    size: number
    isCanceled: StripeStoragePlan['isCanceled']
    isOverrided: StripeStoragePlan['isOverrided']
    planActionLink: StripeStoragePlan['planActionLink']
    priceString: StripeStoragePlan['priceString']
    validToDate: StripeStoragePlan['validToDate']
    period: StripeStoragePlan['period']
}
export type AppStoreSource = {
    sourceName:
        | CAPBAKClientGrantor.AppleStore
        | CAPBAKClientGrantor.AndroidStore
    size: number
    isCanceled: boolean
    validToDate: string
}
export type GrantedCaptureSource = {
    sourceName: CAPBAKClientGrantor.Capture
    size: number
}
export type SourceGroup =
    | CaptureStripeSource
    | AppStoreSource
    | GrantedCaptureSource
export const getStorageSources = createSelector(
    getAggregatedUserGrants,
    getCurrentStripeStoragePlan,
    (userGrant, stripeStoragePlan): SourceGroup[] => {
        const sourceGroups: SourceGroup[] = []
        let grantedSize = 0
        for (const g of userGrant) {
            if (
                g.source === CAPBAKClientGrantor.CaptureStripe &&
                stripeStoragePlan?.size === g.size
            ) {
                sourceGroups.push({
                    sourceName: g.source,
                    size: g.size,
                    isCanceled: stripeStoragePlan.isCanceled,
                    isOverrided: stripeStoragePlan.isOverrided,
                    planActionLink: stripeStoragePlan.planActionLink,
                    validToDate: stripeStoragePlan.validToDate,
                    priceString: getPriceString(
                        stripeStoragePlan.price,
                        stripeStoragePlan.currency,
                    ),
                    period: stripeStoragePlan.period,
                })
                continue
            }

            if (
                g.source === CAPBAKClientGrantor.AppleStore ||
                g.source === CAPBAKClientGrantor.AndroidStore
            ) {
                const validToDate = g.renews_at || g.expires_at
                sourceGroups.push({
                    sourceName: g.source,
                    size: g.size,
                    isCanceled: g.expires_at !== undefined,
                    validToDate: localizedDateStringShort(
                        new Date(validToDate! * 1000),
                    ),
                })
                continue
            }

            grantedSize += g.size
        }

        if (grantedSize > 0) {
            sourceGroups.unshift({
                sourceName: CAPBAKClientGrantor.Capture,
                size: grantedSize,
            })
        }

        return sourceGroups
    },
)

export enum UnavailableReason {
    PLAN_IS_DOWNGRADE = 'PLAN_IS_DOWNGRADE',
    INSUFFICIENT_STORAGE_PLAN = 'INSUFFICIENT_STORAGE_PLAN',
}
export type CaptureStripeProduct = StripeProduct & {
    unavailableReason?: UnavailableReason
    priceString: string
}

export type PlanGroup = {
    storage: string
    monthly: CaptureStripeProduct
    yearly: CaptureStripeProduct
}

const getUnavailableReason = (
    usedSpace: number,
    maxSpace: number,
    consideredPlan: StripeProduct,
    currPlan?: StripeStoragePlan,
) => {
    const sizeGained = consideredPlan.size - (currPlan ? currPlan.size : 0)
    const isPeriodDowngrade =
        currPlan &&
        currPlan.period === 'yearly' &&
        consideredPlan.period === 'monthly'

    if (isPeriodDowngrade) {
        return UnavailableReason.PLAN_IS_DOWNGRADE
    }

    if (usedSpace > maxSpace + sizeGained) {
        return UnavailableReason.INSUFFICIENT_STORAGE_PLAN
    }
}

export const getStoragePlanGroups = createSelector(
    getAvailableProducts,
    getCurrentStripeStoragePlan,
    getUsedStorage,
    getMaxStorage,
    (products, currPlan, usedSpace, maxSpace) => {
        const monthlyList: Map<number, PlanGroup['monthly']> = new Map()
        const yearlyList: Map<number, PlanGroup['yearly']> = new Map()
        const storageList: Map<number, PlanGroup> = new Map()

        products.forEach((p) => {
            const unavailableReason = getUnavailableReason(
                usedSpace,
                maxSpace,
                p,
                currPlan,
            )

            switch (p.period) {
                case 'monthly':
                    monthlyList.set(p.size, {
                        ...p,
                        unavailableReason,
                        priceString: getPriceString(p.price, p.currency),
                    })
                    break
                case 'yearly':
                    yearlyList.set(p.size, {
                        ...p,
                        unavailableReason,
                        priceString: getPriceString(p.price, p.currency),
                    })
                    break
            }

            if (monthlyList.get(p.size) && yearlyList.get(p.size)) {
                storageList.set(p.size, {
                    storage: bytesToSize(p.size),
                    monthly: monthlyList.get(p.size)!,
                    yearly: yearlyList.get(p.size)!,
                })
            }
        })

        return Array.from(storageList.entries())
            .sort((a, b) => b[0] - a[0]) // sort by size
            .map(([_, group]) => group)
    },
)
