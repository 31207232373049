/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKStripeDeleteCCResponse,
    CAPBAKStripeUserCreditCardDeleteParams,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class StripeUserCreditCard<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description This call is used to delete user credit card.
     *
     * @tags stripe
     * @name StripeUserCreditCardDelete
     * @summary Delete Cc Stripe
     * @request DELETE:/stripe_user_credit_card
     */
    stripeUserCreditCardDelete = (
        query: CAPBAKStripeUserCreditCardDeleteParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKStripeDeleteCCResponse,
            void | CAPBAKStripeDeleteCCResponse | CAPBAKHTTPValidationError
        >({
            path: `/stripe_user_credit_card`,
            method: 'DELETE',
            query: query,
            format: 'json',
            ...params,
        })
}
