/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKPaymentInfoResponse,
    CAPBAKStripePaymentMethodGetParams,
    CAPBAKStripePaymentMethodPostParams,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class StripePaymentMethod<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description Update the web store/stripe payment details of a user
     *
     * @tags stripe
     * @name StripePaymentMethodPost
     * @summary Post Stripe Payment Method
     * @request POST:/stripe_payment_method
     */
    stripePaymentMethodPost = (
        query: CAPBAKStripePaymentMethodPostParams,
        params: RequestParams = {},
    ) =>
        this.request<any, void | CAPBAKHTTPValidationError>({
            path: `/stripe_payment_method`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
    /**
     * @description Get the web store/stripe payment details of a user
     *
     * @tags stripe
     * @name StripePaymentMethodGet
     * @summary Get Stripe Payment Method
     * @request GET:/stripe_payment_method
     */
    stripePaymentMethodGet = (
        query: CAPBAKStripePaymentMethodGetParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKPaymentInfoResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/stripe_payment_method`,
            method: 'GET',
            query: query,
            format: 'json',
            ...params,
        })
}
