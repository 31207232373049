import { Stripe as StripeClient } from '@capture/client-api/src/schemas/Stripe'
import { StripeProducts } from '@capture/client-api/src/schemas/StripeProducts'
import { StripePurchase } from '@capture/client-api/src/schemas/StripePurchase'
import { StripePaymentMethod } from '@capture/client-api/src/schemas/StripePaymentMethod'
import { UpdateStripePurchase } from '@capture/client-api/src/schemas/UpdateStripePurchase'
import { StripeUserCreditCard } from '@capture/client-api/src/schemas/StripeUserCreditCard'
import type { CAPBAKStripePurchasePostParams } from '@capture/client-api/src/schemas/data-contracts'
import type {
    ExtraQueryParamsOf,
    StripePaymentInfo,
    StripeProductsMode,
    StripeProductsResponse,
    StripePurchaseResponse,
} from '~/@types/backend-types'
import { getAuthToken, getStoredServiceDict } from '../externals'
import { APIService } from './APIService'

export class StripeService extends APIService {
    private stripeClient: StripeClient
    private stripeProductsClient: StripeProducts
    private stripePurchaseClient: StripePurchase
    private stripePaymentMethodClient: StripePaymentMethod
    private updateStripePurchaseClient: UpdateStripePurchase
    private stripeUserCreditCardClient: StripeUserCreditCard

    constructor(host: string, authToken: string) {
        super(host, authToken)

        this.stripeClient = this.createAPIClient(StripeClient)
        this.stripeProductsClient = this.createAPIClient(StripeProducts)
        this.stripePurchaseClient = this.createAPIClient(StripePurchase)
        this.stripePaymentMethodClient =
            this.createAPIClient(StripePaymentMethod)
        this.updateStripePurchaseClient =
            this.createAPIClient(UpdateStripePurchase)
        this.stripeUserCreditCardClient =
            this.createAPIClient(StripeUserCreditCard)
    }

    public async checkCustomerExists() {
        return this.stripeClient.customerExistsGet(this.commonQueryParams)
    }

    public async createCustomer({
        name,
        email,
    }: {
        name: string
        email: string
    }) {
        return this.stripeClient.createCustomerPost({
            ...this.commonQueryParams,
            name,
            email,
        })
    }

    public async createSetupIntent() {
        return this.stripeClient.createSetupIntentPost(this.commonQueryParams)
    }

    public async finishSetupIntent(setupIntentId: string) {
        return this.stripeClient.finishSetupIntentPost({
            ...this.commonQueryParams,
            setup_intent_id: setupIntentId,
        })
    }

    public async getAllSubscriptions() {
        return this.stripeClient.subscriptionsGet(this.commonQueryParams)
    }

    public async getStripeProducts(
        mode: StripeProductsMode /* 'test'|'production'*/,
    ): Promise<StripeProductsResponse> {
        const response = await this.stripeProductsClient
            .stripeProductsGet({ ...this.commonQueryParams, [mode]: true })
            .unwrapError()

        return response.data as StripeProductsResponse
    }

    public async validateStripePurchase(
        subscriptionId: string,
        paymentIntentId: string,
    ) {
        return this.stripePurchaseClient
            .validatePurchaseDetail({
                ...this.commonQueryParams,
                subscriptionId,
                payment_intent_id: paymentIntentId,
            })
            .unwrapError()
    }

    public async getStripePaymentMethodInfo() {
        const response = await this.stripePaymentMethodClient
            .stripePaymentMethodGet(this.commonQueryParams)
            .unwrapError()

        return response.data as StripePaymentInfo
    }

    public async purchaseStripePlan(plan: string) {
        return this.stripeClient
            .v1PurchasePost({
                ...this.commonQueryParams,
                plan,
            })
            .unwrapError()
    }

    public async updateStripePlan(plan: string) {
        return this.stripeClient
            .v1PurchaseUpdate({
                ...this.commonQueryParams,
                plan,
            })
            .unwrapError()
    }

    /**
     * @deprecated This function is deprecated and will be removed after stripe setup intent migration.
     */
    public async stripePurchase({
        plan,
        token,
        card,
    }: ExtraQueryParamsOf<CAPBAKStripePurchasePostParams>): Promise<StripePurchaseResponse> {
        const response = await this.stripePurchaseClient
            .stripePurchasePost({
                ...this.commonQueryParams,
                plan,
                token,
                card,
            })
            .unwrapError()

        return response.data as StripePurchaseResponse
    }

    /**
     * @deprecated This function is deprecated and will be removed after stripe setup intent migration.
     */
    public async postStripePaymentMethod(token: string, card: string) {
        return this.stripePaymentMethodClient
            .stripePaymentMethodPost({ ...this.commonQueryParams, token, card })
            .unwrapError()
    }

    /**
     * @deprecated This function is deprecated and will be removed after stripe setup intent migration.
     */
    public async updateCurrentPlan(plan: string) {
        return this.updateStripePurchaseClient
            .updateStripePurchasePost({
                ...this.commonQueryParams,
                plan,
            })
            .unwrapError()
    }

    /**
     * @deprecated This function is deprecated and will be removed after stripe setup intent migration.
     */
    public async deleteUserCreditCard(cardId: string) {
        return this.stripeUserCreditCardClient
            .stripeUserCreditCardDelete({
                ...this.commonQueryParams,
                card_id: cardId,
            })
            .unwrapError()
    }
}

let instance: StripeService | undefined

export function getStripeService() {
    if (instance) {
        return instance
    }

    const hosts = getStoredServiceDict()
    const token = getAuthToken()

    if (hosts === undefined || token === undefined) {
        throw new Error('Missing serviceDict or authToken')
    }

    instance = new StripeService(hosts.appHost, token)
    return instance
}
