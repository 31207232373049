import {
    Accordion,
    AccordionItem,
    Checkbox,
    PageCard,
    PagePrimaryButton,
    PagePrimaryDestructiveButton,
    Prompt,
    Typography,
} from '@capture/capture-components'
import SvgArrowBack from '@capture/capture-components/src/icons/ArrowBack'
import type {
    DataProtectionConsentKey,
    MigratedDataProtectionConsentData,
    UnMigratedDataProtectionConsentData,
} from '@capture/client/@types/backend-types'
import { getServiceProvider } from '@capture/client/API/HostProvider'
import { _ } from '@capture/client/assets/localization/util'
import { fontSize } from '@capture/client/assets/styleConstants'
import { IconTextButton } from '@capture/client/components/Common/IconTextButton'
import { LoadingPage } from '@capture/client/components/Common/LoadingPage'
import { PageWrapper } from '@capture/client/components/Common/PageWrapper'
import { TwoAreasTopNavBar } from '@capture/client/components/Navigation/TwoAreasTopNavBar'
import { PRIVACY_STATEMENT_LINK } from '@capture/client/config/constants'
import { Settings, Takeout } from '@capture/client/routing/pages'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { mapObject } from '@capture/client/utilities/dictionaryUtils'
import { isInsideAppRoute } from '@capture/client/utilities/navigation'
import { ConsentToggle } from './ConsentToggle'
import { AccessDataPrompt, DeleteAccountPrompt } from './PrivacyPagePrompt'

// we need extra spacing in this section
const AccordionItemConsent = styled(AccordionItem)`
    gap: 1.5rem;

    > :last-child {
        margin-top: 0.5rem;
    }
`

const VerticalSpacer = styled.div.attrs({
    'aria-hidden': true,
    role: 'presentation',
})<{ $amount: number }>`
    padding-bottom: ${({ $amount }) => `${$amount}rem`};
`

const CheckboxList = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`

const withTSL = (s: string) =>
    s.replace('%company_name%', 'Telenor Software Lab AS')

const PrivacyStatementParagraph = () => {
    const [_s, beforeLink, linkText, afterLink] = withTSL(
        _('privacy_desc__processes_with_privacy_policy__format'),
    ).match('(.*)%link_start%(.*)%link_end%(.*)')!
    return (
        <Typography.Paragraph>
            {beforeLink}
            <Typography.Link href={PRIVACY_STATEMENT_LINK}>
                {linkText}
            </Typography.Link>
            {afterLink}
        </Typography.Paragraph>
    )
}

type ConsentStateValue = {
    checked: boolean
    disabled: boolean
}
type ConsentStateValues =
    | {
          [K in keyof MigratedDataProtectionConsentData]: ConsentStateValue
      }
    | {
          [K in keyof UnMigratedDataProtectionConsentData]: ConsentStateValue
      }
const useConsentValues = (): {
    consentStateValues: ConsentStateValues | null
    onChangeConsentValue: (
        key: DataProtectionConsentKey,
        value: boolean,
    ) => void
} => {
    const [consentStateValues, setConsentStateValues] =
        useState<ConsentStateValues | null>(null)

    useEffect(() => {
        async function getDataProtectionConsentValues() {
            try {
                const service =
                    getServiceProvider().getAppServiceForLoggedInUserDefaults()
                const consents = await service.getDataProtectionConsentValues()
                setConsentStateValues(
                    mapObject(consents, (c) => ({
                        checked: c,
                        disabled: false,
                    })),
                )
            } catch (e) {
                console.error('when getDataProtectionConsentValues:', e)
            }
        }

        getDataProtectionConsentValues()
    }, [])

    const onChangeConsentValue = async (
        key: DataProtectionConsentKey,
        checked: boolean,
    ) => {
        // Optimistic update
        setConsentStateValues((prev) => ({
            ...prev!,
            [key]: { checked, disabled: true } as ConsentStateValue,
        }))

        try {
            const service =
                getServiceProvider().getAppServiceForLoggedInUserDefaults()
            const { consents } =
                await service.updateDataProtectionConsentValues({
                    [key]: checked,
                })

            setConsentStateValues(
                mapObject(consents, (c) => ({
                    checked: c.value,
                    disabled: false,
                })),
            )
        } catch (e) {
            console.error('when updateDataProtectionConsentValues:', e)
            setConsentStateValues((prev) => ({
                ...prev!,
                [key]: {
                    checked: !checked,
                    disabled: false,
                } as ConsentStateValue,
            }))
        }
    }

    return {
        consentStateValues,
        onChangeConsentValue,
    }
}

export const PrivacyPage = () => {
    const { consentStateValues, onChangeConsentValue } = useConsentValues()
    const navigate = useNavigate()
    const navigateToSettings = () => navigate(Settings.url)
    const navigateToTakeout = () => navigate(Takeout.url)
    const insideMobileApp = isInsideAppRoute()

    if (consentStateValues === null) {
        return <LoadingPage />
    }

    const backButton =
        insideMobileApp === true ? null : (
            <IconTextButton
                onClick={navigateToSettings}
                text={_('settings')}
                icon={SvgArrowBack}
                fontSize={fontSize.small_14}
            />
        )

    const navBar = <TwoAreasTopNavBar left={backButton} />

    return (
        <PageWrapper navBar={navBar} hideFooter={insideMobileApp === true}>
            <PageCard>
                <Accordion type="multiple">
                    <AccordionItem title={_('stay_up_to_date')}>
                        <Typography.Paragraph>
                            {_('privacy_desc__let_you_know_about_updates')}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            {withTSL(_('can_contact_me_via__format'))}
                        </Typography.Paragraph>
                        <CheckboxList>
                            <Checkbox
                                {...consentStateValues.stay_up_to_date__email}
                                onCheckedChange={(checked) =>
                                    checked !== 'indeterminate' &&
                                    onChangeConsentValue(
                                        'stay_up_to_date__email',
                                        checked,
                                    )
                                }>
                                {_('email')}
                            </Checkbox>
                            <Checkbox
                                {...consentStateValues.stay_up_to_date__push_notification}
                                onCheckedChange={(checked) =>
                                    checked !== 'indeterminate' &&
                                    onChangeConsentValue(
                                        'stay_up_to_date__push_notification',
                                        checked,
                                    )
                                }>
                                {_('push_notification')}
                            </Checkbox>
                            <Checkbox
                                {...consentStateValues.stay_up_to_date__sms}
                                onCheckedChange={(checked) =>
                                    checked !== 'indeterminate' &&
                                    onChangeConsentValue(
                                        'stay_up_to_date__sms',
                                        checked,
                                    )
                                }>
                                {_('SMS')}
                            </Checkbox>
                        </CheckboxList>
                    </AccordionItem>

                    <AccordionItemConsent title={_('consents')}>
                        {'face_grouping' in consentStateValues ? (
                            <>
                                <ConsentToggle
                                    {...consentStateValues.face_grouping}
                                    title={_('face_grouping')}
                                    description={withTSL(
                                        _(
                                            'privacy_desc__can_process_face_details__format',
                                        ),
                                    )}
                                    onCheckedChange={(checked) =>
                                        onChangeConsentValue(
                                            'face_grouping',
                                            checked,
                                        )
                                    }
                                />
                                <ConsentToggle
                                    {...consentStateValues.advanced_filtering_and_categorization}
                                    title={_(
                                        'advanced_filtering_and_categorization',
                                    )}
                                    description={withTSL(
                                        _(
                                            'privacy_desc__can_process_images_advanced_filtering__format',
                                        ),
                                    )}
                                    onCheckedChange={(checked) =>
                                        onChangeConsentValue(
                                            'advanced_filtering_and_categorization',
                                            checked,
                                        )
                                    }
                                />
                            </>
                        ) : (
                            <ConsentToggle
                                {...consentStateValues.facial_recognition}
                                title={_('facial_recognition')}
                                description={withTSL(
                                    _(
                                        'privacy_desc__can_process_images_categorisation__format',
                                    ),
                                )}
                                onCheckedChange={(checked) =>
                                    onChangeConsentValue(
                                        'facial_recognition',
                                        checked,
                                    )
                                }
                            />
                        )}

                        <ConsentToggle
                            {...consentStateValues.help_us_improve}
                            title={_('help_us_improve')}
                            description={withTSL(
                                _(
                                    'privacy_desc__can_process_data_for_improvement__format',
                                ),
                            )}
                            onCheckedChange={(checked) =>
                                onChangeConsentValue('help_us_improve', checked)
                            }
                        />
                    </AccordionItemConsent>

                    <AccordionItem title={_('access_your_data')}>
                        <PrivacyStatementParagraph />
                        <Typography.Paragraph>
                            {withTSL(
                                _(
                                    'privacy_desc__download_personal_information__format',
                                ),
                            )}
                        </Typography.Paragraph>
                        <Prompt.Root>
                            <Prompt.Trigger asChild>
                                <PagePrimaryButton>
                                    {_('privacy_request_a_copy_of_data')}
                                </PagePrimaryButton>
                            </Prompt.Trigger>
                            <AccessDataPrompt />
                        </Prompt.Root>

                        <VerticalSpacer $amount={1} />

                        <Typography.Paragraph>
                            {_('privacy_download_all')}
                        </Typography.Paragraph>
                        <PagePrimaryButton onPress={navigateToTakeout}>
                            {_('privacy_download_all_button')}
                        </PagePrimaryButton>
                    </AccordionItem>

                    <AccordionItem
                        title={_('delete_your_account')}
                        data-cy="deleteCaptureAccountExpandable">
                        <Typography.Paragraph>
                            {withTSL(
                                _('you_can_request_delete_account__format'),
                            )}
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            <Typography.Bold>
                                {_(
                                    'delete_account_includes_irreversible_deletion',
                                )}
                            </Typography.Bold>
                        </Typography.Paragraph>
                        <Typography.Paragraph>
                            {_('it_may_take_up_to_days__format').replace(
                                '%d',
                                '30',
                            )}
                        </Typography.Paragraph>
                        <Prompt.Root>
                            <Prompt.Trigger asChild>
                                <PagePrimaryDestructiveButton data-cy="requestAccountDeletionBtn">
                                    {_('request_deletion')}
                                </PagePrimaryDestructiveButton>
                            </Prompt.Trigger>
                            <DeleteAccountPrompt />
                        </Prompt.Root>
                    </AccordionItem>

                    {insideMobileApp === true && <VerticalSpacer $amount={2} />}
                </Accordion>
            </PageCard>
        </PageWrapper>
    )
}
