import { getCurrentLocale } from '~/config/constants'
import { strings as bgStrings } from './bg-BG'
import { strings as enStrings } from './en-US'
import { strings as noStrings } from './nb-NO'
import { strings as svStrings } from './sv-SE'

type TranslationKeys = keyof typeof enStrings

export type TranslationModule = Record<TranslationKeys, string>

const translationCache: { [key: string]: TranslationModule } = {}

export function cacheLocaleStrings(locale: string) {
    if (translationCache[locale]) {
        return
    }

    let translations

    switch (locale) {
        case 'bg_BG':
            translations = bgStrings
            break

        case 'nb_NO':
            translations = noStrings
            break

        case 'sv_SE':
            translations = svStrings
            break

        case 'en_US':
            translations = enStrings
            break

        default:
            translations = enStrings
            break
    }
    translationCache[locale] = translations as TranslationModule
}

export function _(stringKey: TranslationKeys): string {
    const locale = getCurrentLocale()
    cacheLocaleStrings(locale)
    return translationCache[locale]?.[stringKey] || enStrings[stringKey]
}

export const getStringByAmount = (
    amount: number,
    singularStr: string,
    pluralStr: string,
) => (amount === 1 ? singularStr : pluralStr)

export const getStringWithAmount = (
    amount: number,
    singularStr: string,
    pluralStrFormat: string,
    placeholder = '%d',
) => {
    return getStringByAmount(amount, singularStr, pluralStrFormat).replace(
        placeholder,
        amount.toString(),
    )
}

/** Extract [beforeLinkText, linkInnerText, afterLinkText] from a string using %link_start% and %link_end% delimiters */
export const getLinkParts = (
    stringFormat: string,
): [string, string, string] => {
    const m = stringFormat.match(/(.*)%link_start%(.*)%link_end%(.*)/)
    return m ? [m[1], m[2], m[3]] : ['', stringFormat, '']
}
