/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
    CAPBAKHTTPValidationError,
    CAPBAKUpdateStripePurchasePostParams,
    CAPBAKUpdateStripePurchaseResponse,
} from './data-contracts'
import { HttpClient, RequestParams } from './http-client'

export class UpdateStripePurchase<
    SecurityDataType = unknown,
> extends HttpClient<SecurityDataType> {
    /**
     * @description Update the web store/stripe subscription of a user
     *
     * @tags stripe
     * @name UpdateStripePurchasePost
     * @summary Update Stripe Purchase
     * @request POST:/update_stripe_purchase
     */
    updateStripePurchasePost = (
        query: CAPBAKUpdateStripePurchasePostParams,
        params: RequestParams = {},
    ) =>
        this.request<
            CAPBAKUpdateStripePurchaseResponse,
            void | CAPBAKHTTPValidationError
        >({
            path: `/update_stripe_purchase`,
            method: 'POST',
            query: query,
            format: 'json',
            ...params,
        })
}
