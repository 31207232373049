import type { EmptyObject, Middleware } from '@reduxjs/toolkit'
import { loadTrashContent, updateTrashContent } from '~/API/job'
import { fetchAccountInfo } from '~/API/currentUser'
import { TRASH_FETCH_LIMIT } from '~/config/constants'
import { FileUploadSucceeded } from '../uploader/actions'
import { isType } from '../common/actions'
import {
    FilesDeletionSucceeded,
    FilesRestorationSucceeded,
} from '../job/actions'
import { getTimelineJobID } from '../timeline/selectors'
import { timelineReducerMapObj } from '../timeline/reducers'
import { FetchMoreTrashTriggered } from './actions'
import { getTrashFileLatestOffset } from './selectors'
import { trashReducerMapObj } from './reducer'

export const trashMiddlewareReducerMapObj = {
    ...timelineReducerMapObj,
    ...trashReducerMapObj,
}

type TrashMiddlewareState = StateOfReducerMapObj<
    typeof trashMiddlewareReducerMapObj
>

export const trashMiddleware: Middleware<EmptyObject, TrashMiddlewareState> =
    (store) => (next) => (action) => {
        const state = store.getState()
        const timelineID = getTimelineJobID(state)
        const latestOffset = getTrashFileLatestOffset(state)

        if (
            (isType(action, FilesDeletionSucceeded) ||
                isType(action, FilesRestorationSucceeded)) &&
            action.payload.jobID === timelineID
        ) {
            fetchAccountInfo(store.dispatch)
            updateTrashContent(store.dispatch, latestOffset, TRASH_FETCH_LIMIT)
        }

        // Handling the case when uploading a deleted file
        if (isType(action, FileUploadSucceeded)) {
            updateTrashContent(store.dispatch, latestOffset, TRASH_FETCH_LIMIT)
        }

        if (isType(action, FetchMoreTrashTriggered)) {
            loadTrashContent(
                store.dispatch,
                latestOffset + TRASH_FETCH_LIMIT,
                TRASH_FETCH_LIMIT,
            )
        }

        next(action)
    }
